import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Link from 'components/L10nLink'
import { FormattedMessage, injectIntl } from 'react-intl'

const Footer = injectIntl(({ navigation, intl: { locale: lang } }) => {
  const startYear = 2018
  const dateNow = new Date()
  const yearNow = dateNow.getFullYear()
  const copyYears = yearNow > startYear ? `${startYear} - ${yearNow}` : yearNow

  return (
    <footer className="footer bg-true-black text-white text-sm py-4 lg:py-0">
      <div className="container h-full align-middle flex flex-col justify-center">
        <div className="flex flex-col md:flex-row-reverse justify-between items-start items-center">
          <ul
            role="navigation"
            className="footer-nav list-reset flex flex-col items-center sm:flex-row sm:items-start lg:m-0"
          >
            {navigation &&
              navigation.map(({ link, title }, idx) => (
                <li key={`${idx}-${link}`}>
                  <Link to={link}>{title[lang]}</Link>
                </li>
              ))}
          </ul>

          <div className="text-center md:text-left">
            <div className="footer-copy">
              {copyYears} THE CHANGEMAKERS{` `}
              <br className="sm:hidden" />
              <FormattedMessage id="allRightsReserved" />
            </div>
            <div className="footer-credits">
              Design by{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ralev.com/"
                title="Premium brand design"
              >
                Ralev.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
})

export default () => (
  <StaticQuery
    query={graphql`
      query {
        settings: file(relativePath: { eq: "settings/footer.yml" }) {
          footer: childSettingsYaml {
            ...NavigationMenuItemFragmet
          }
        }
      }
    `}
    render={({
      settings: {
        footer: { navigation = [] },
      },
    }) => {
      return <Footer navigation={navigation} />
    }}
  />
)
