import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { injectIntl } from 'react-intl'
import { find } from 'lodash'
import locales from '../../config/locales'
import { Location } from '@reach/router'
import { LayoutContextConsumer } from 'layouts/index'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { mapProps } from 'recompose'

export const getLocalizedPath = ({ path, code }) =>
  find(locales, { code }).default ? path : `/${code}${path}`

const LocalizedLink = React.forwardRef(
  ({ to, intl: { locale }, ...props }, ref) => {
    const path = getLocalizedPath({
      path: to,
      code: locale,
    })

    return (
      <LayoutContextConsumer>
        {({ headerHeight }) => {
          return (
            <Location>
              {({ location: { pathname: currentPath } }) => {
                // let linkPath

                // if (to.startsWith('#')) {
                //   linkPath = `/`
                // }

                const pathParts = path.split(`#`)
                // linkPath = pathParts[0]

                const hash = path.includes(`#`)
                  ? pathParts[pathParts.length - 1]
                  : ''

                if (hash) {
                  return <AnchorLink ref={ref} {...props} to={path} stripHash />
                }

                return <GatsbyLink ref={ref} {...props} to={path} />
              }}
            </Location>
          )
        }}
      </LayoutContextConsumer>
    )
  }
)

const Link = React.forwardRef(({ children, to, ...other }, ref) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^(#|\/(?!\/))/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <LocalizedLink ref={ref} to={to} {...other}>
        {children}
      </LocalizedLink>
    )
  }

  return (
    <a ref={ref} href={to} target="_blank" rel="noopener noreferrer" {...other}>
      {children}
    </a>
  )
})

const MarkdownLink = mapProps(({ href, ...props }) => ({
  ...props,
  to: href,
  intl: {},
}))(Link)

export { LocalizedLink, MarkdownLink }

export default injectIntl(Link)
