import React, { createContext } from 'react'
import margin from 'styles/tailwind/margin'
import styled from 'styled-components'
import posed from 'react-pose'
import { lifecycle, withStateHandlers, compose } from 'recompose'

const PopupContentPosed = posed.div({
  open: {
    opacity: 1,
    scale: 1,
  },

  closed: {
    opacity: 0.5,
    scale: 0.5,

    transition: {
      duration: 100,
    },
  },
})

export const PopupContent = styled(PopupContentPosed)`
  width: 758px;
  @media (min-width: 826px) {
    width: 640px;
  }

  max-width: calc(100% - 2rem);
  overflow: auto;
  margin-top: ${margin[12]};
  margin-bottom: ${margin[12]};
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  overflow: hidden;

  iframe {
    height: 100% !important;
  }
`

const PopupPosed = posed.div({
  open: {
    applyAtStart: {
      display: 'flex',
    },
    opacity: 1,
    delayChildren: 100,
    // beforeChildren: true,
  },
  closed: {
    opacity: 0,
    applyAtEnd: {
      display: 'none',
    },
  },
})

export const PopupBase = styled(PopupPosed)`
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
`

// export const PopupContextShape = {
//   togglePopup: PropTypes.func,
// }

// export const enhanceWithPopupContext = getContext(PopupContextShape)

const Popup = compose(
  withStateHandlers(
    { popupPose: '' },
    {
      togglePopup: ({ popupPose }) => () => ({
        popupPose: popupPose === 'open' ? 'closed' : 'open',
      }),

      escFunction: ({ popupPose }) => e => {
        if (e.keyCode === 27 && popupPose === 'open') {
          e.preventDefault()

          return {
            popupPose: 'closed',
          }
        }
      },
    }
  ),

  lifecycle({
    componentDidMount() {
      const { escFunction } = this.props

      if (escFunction) {
        document.addEventListener('keydown', escFunction, false)
      }
    },

    componentWillUnmount() {
      const { escFunction } = this.props

      if (escFunction) {
        document.removeEventListener('keydown', escFunction, false)
      }
    },
  })

  // withContext(PopupContextShape, ({ togglePopup }) => ({
  //   togglePopup,
  // }))
)(({ popupPose, togglePopup, popupContent, children, ctx, ...props }) => (
  <ctx.Provider value={{ togglePopup }}>
    {children}

    <PopupBase initialPose="closed" pose={popupPose} onClick={togglePopup}>
      <PopupContent {...props}>
        <div
          className="google-form-wrapper w-full"
          dangerouslySetInnerHTML={{ __html: popupContent }}
        />
      </PopupContent>
    </PopupBase>
  </ctx.Provider>
))

export default Popup

export function createPopup() {
  const Context = createContext({
    togglePopup: () => {},
  })

  return {
    Popup(props) {
      return <Popup ctx={Context} {...props} />
    },
    Context,
  }
}
