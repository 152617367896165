import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Headroom from 'react-headroom'
import Link from 'components/L10nLink'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import LangSwitcher from 'components/LangSwitcher'
import WithIntl from 'components/WithIntl'
import posed from 'react-pose'
import styled from 'styled-components'
import MenuToggler from 'components/MenuToggler'
import { FaUnlockAlt } from 'react-icons/fa'
import colors from 'styles/tailwind/colors'
import screens from 'styles/tailwind/screens'
import { withStateHandlers, lifecycle, compose } from 'recompose'

const NavMenuLink = ({ link, title, ...props }) => (
  <Link {...props} to={link}>
    {title}
  </Link>
)
const DesktopMainNavMenuLink = ({ link, title }) => (
  <Link to={link}>
    <span className="relative z-10 whitespace-no-wrap">{title}</span>
  </Link>
)

const NavMenu = ({
  items = [],
  className = '',
  listClassName = '',
  id,
  locale,
  NavMenuLinkComponent = NavMenuLink,
  navMenuItemClassName = '',
  navMenuLinkClassName = '',
  navMenuOnClick = () => {},
}) => {
  const classNames = className.split(' ')
  classNames.push('menu')

  const navProps = { className: classNames.join(' '), id }

  return (
    <nav {...navProps}>
      <ul className={`list-reset ${listClassName}`}>
        {items.length &&
          items.map(({ link, title = {} }) => (
            <li key={`${locale}-${link}`} className={navMenuItemClassName}>
              <NavMenuLinkComponent
                className={navMenuLinkClassName}
                link={link}
                title={title[locale]}
                onClick={navMenuOnClick}
              />
            </li>
          ))}
      </ul>
    </nav>
  )
}

const Logo = React.forwardRef(({ logoFile, siteTitle }, ref) => (
  <div
    ref={ref}
    className="flex flex-col self-start items-stretch"
    style={{ height: '200%' }}
  >
    {logoFile && logoFile.childImageSharp ? (
      [
        <div key="logo" className="flex items-center align-middle flex-1">
          <Img className="w-full" fluid={logoFile.childImageSharp.logo} />
        </div>,
        <div key="logo-hover" className="flex items-center align-middle flex-1">
          <Img className="w-full" fluid={logoFile.childImageSharp.logo} />
        </div>,
      ]
    ) : (
      <h1 style={{}}>{siteTitle}</h1>
    )}
  </div>
))

const LogoPosed = styled(
  posed(Logo)({
    hoverable: true,
    hover: {
      y: '-50%',
    },
    init: {
      y: 0,
    },
    transition: {
      duration: 600,
    },
  })
)`
  width: 160px;
`

const LogoLink = styled(
  React.forwardRef(({ className, ...props }, ref) => (
    <AnchorLink
      {...props}
      ref={ref}
      className={`logo block h-full ${className}`}
      to="/#___gatsby"
      stripHash
    />
  ))
)`
  width: 160px;
  @media (min-width: ${screens.md}) {
    width: 260px;
  }
`

const Header = React.forwardRef(
  (
    {
      siteTitle,
      logoFile,
      navigation: navItems = [],
      primaryButton = {},
      handleOffcanvasToggle,
      offCanvasPose,
      intl: { locale },
    },
    ref
  ) => {
    const navItemsOffcanvas = [
      { link: `#___gatsby`, title: { en: `Home`, bg: `Начало` } },
      ...navItems,
    ]

    return (
      <>
        <Headroom
          className="z-50 fixed pin-l pin-r pin-t"
          disableInlineStyles={true}
          disable={offCanvasPose === 'open'}
          upTolerance={40}
          downTolerance={10}
        >
          <header ref={ref} className="header bg-white shadow">
            <div className="container h-full flex flex-row items-center justify-between overflow-hidden">
              <MenuToggler
                className="md:hidden flex-no-shrink flex-no-grow"
                pose={offCanvasPose}
                onClick={handleOffcanvasToggle}
              />

              <LogoLink>
                <LogoPosed logoFile={logoFile} siteTitle={siteTitle} />
              </LogoLink>

              <NavMenu
                className="ml-auto mr-5 hidden md:block md:text-sm lg:text-base"
                listClassName="flex flex-row m-0"
                id="main-menu"
                items={navItems}
                locale={locale}
                NavMenuLinkComponent={DesktopMainNavMenuLink}
              />

              <LangSwitcher
                className="hidden md:block mx-2 lg:mx-4 flex-no-grow"
                linkClassName="btn btn-no-shadow border-0 font-normal md:px-2 lg:px-4 hover:text-skyblue"
              />

              {primaryButton && primaryButton.title && (
                <>
                  <Link
                    className="flex lg:hidden h-full md:h-16 w-12 p-2 border-0 shadow-none flex-row justify-center text-black"
                    to={primaryButton.link}
                  >
                    <FaUnlockAlt className="inline-block self-center w-5 h-5" />
                  </Link>

                  <Link
                    className="hidden lg:block btn btn-outline"
                    to={primaryButton.link}
                  >
                    <span className="hidden lg:inline">
                      {primaryButton.title[locale]}
                    </span>
                  </Link>
                </>
              )}
            </div>
          </header>
        </Headroom>

        <MobileOffcanvas
          initialPose="closed"
          pose={offCanvasPose}
          className="md:hidden shadow-md"
        >
          <NavMenu
            navMenuOnClick={handleOffcanvasToggle}
            items={navItemsOffcanvas}
            locale={locale}
            listClassName="m-0"
            navMenuLinkClassName="block px-5 py-3"
            navMenuItemClassName="font-kelson font-bold border-b-2 border-grey-lighter"
          />
          <LangSwitcher
            display="name"
            linkClassName="block px-5 py-3"
            listItemClassName="font-kelson font-bold border-b-2 border-grey-lighter"
          />
        </MobileOffcanvas>
      </>
    )
  }
)

const offCanvasTransition = {
  ease: 'easeInOut',
}

// const MobileOffcanvasBackdrop = styled(posed.div({
//   open: {
//     applyAtStart: { display: 'block' }
//   },

//   closed: {
//     opacity: 0,
//   }
// }))

const MobileOffcanvas = styled(
  posed.div({
    open: {
      x: 0,
      transition: offCanvasTransition,
    },

    closed: {
      x: '-100%',
      transition: offCanvasTransition,
    },
  })
)`
  position: fixed;
  width: 300px;
  max-width: (100% - 20px);
  top: 50px;
  bottom: 0;
  left: 0;
  background-color: ${colors['grey-lightest']};
  z-index: 1000;
  transform: translateX(-100%);
`

const withOffCanvasStateHandlers = withStateHandlers(
  {
    offCanvasPose: 'closed',
  },
  {
    handleOffcanvasToggle: ({ offCanvasPose }) => () => {
      return {
        offCanvasPose: offCanvasPose === 'closed' ? 'open' : 'closed',
      }
    },
  }
)

const withLifecycle = lifecycle({
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.offCanvasPose === 'open') {
        this.props.handleOffcanvasToggle()
      }
    }
  },
})

const HeaderEnhanced = compose(
  WithIntl,
  withOffCanvasStateHandlers,
  withLifecycle
)(Header)

export default React.forwardRef((props, ref) => (
  <StaticQuery
    query={graphql`
      {
        logo: file(relativePath: { eq: "changemakers-logo.png" }) {
          id
          childImageSharp {
            logo: fluid(maxHeight: 34, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        settingsFooter: file(
          relativeDirectory: { eq: "settings" }
          name: { eq: "header" }
        ) {
          childSettingsYaml {
            ...NavigationMenuItemFragmet
            ...NavigationMainButtonFragment
          }
        }
      }
    `}
    render={data => {
      const {
        logo,
        settingsFooter: { childSettingsYaml: settingsFooter = {} },
      } = data
      const { navigation, primaryButton } = settingsFooter
      return (
        <HeaderEnhanced
          {...props}
          ref={ref}
          logoFile={logo}
          navigation={navigation}
          primaryButton={primaryButton}
        />
      )
    }}
  />
))
