import { graphql } from 'gatsby'
// import React from 'react'
import { createPopup } from './Popup'

const { Popup: ApplyPopup, Context } = createPopup()

export default ApplyPopup
export { Context as ApplyPopupContext }

export const settingsApplyFormFragment = graphql`
  fragment SettingsApplyGoogleFormCodeFragment on Query {
    generalSettings: file(
      relativeDirectory: { eq: "settings" }
      name: { eq: "general" }
    ) {
      yaml: childSettingsYaml {
        googleformApplyCode
      }
    }
  }
`
