module.exports = {
  xs: { max: '575px' },
  sm: '576px',
  'sm-only': { min: '576px', max: '767px' },
  'sm-max': { max: '767px' },
  md: '768px',
  'md-only': { min: '768px', max: '991px' },
  'md-max': { max: '991px' },
  lg: '992px',
  'lg-only': { min: '992px', max: '1199px' },
  'lg-max': { max: '1199px' },
  xl: '1200px',
  'xl-only': { min: '1200px', max: '1379px' },
  'xl-max': { max: '1379px' },
  xxl: '1380px',
}
