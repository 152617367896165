import React from 'react'
import { LocalizedLink } from 'components/L10nLink'
import { injectIntl } from 'react-intl'
import locales from '../../config/locales'
// import { Location } from '@reach/router'
import { PageContextConsumer } from 'layouts/index'

const LangSwitcher = ({
  intl: { locale: current },
  display = 'code',
  linkClassName = '',
  listItemClassName = '',
  ...props
}) => (
  <PageContextConsumer>
    {({ originalPath }) => {
      return (
        <div className={`lang-switcher menu ${props.className}`}>
          {locales.length && (
            <ul className="list-reset m-0">
              {locales.map(({ code, ...locale }) => {
                if (code !== current) {
                  return (
                    <li
                      key={code}
                      className={`lang-switcher-item lang-switcher-item-${code} ${listItemClassName}`}
                    >
                      <LocalizedLink
                        className={linkClassName}
                        intl={{ locale: code }}
                        to={originalPath}
                      >
                        {locale[display] || code}
                      </LocalizedLink>
                    </li>
                  )
                }

                return null
              })}
            </ul>
          )}
        </div>
      )
    }}
  </PageContextConsumer>
)

export default injectIntl(LangSwitcher)
