import React, { forwardRef } from 'react'
import posed from 'react-pose'

const TogglerBar = forwardRef(({ className: userClassName, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={`block my-1 w-full h-1 bg-black ${userClassName}`}
  />
))

const barPoseTransition = {
  originX: 0,
  originY: '50%',
}

const Bar1 = posed(TogglerBar)({
  open: {
    // x: '3px',
    y: '0',
    rotate: '45deg',
    ...barPoseTransition,
  },
  closed: {
    x: 0,
    y: 0,
    rotate: '0deg',
    ...barPoseTransition,
  },
})

const Bar2 = posed(TogglerBar)({
  open: {
    rotateY: '-90deg',
    opacity: 0,
  },
  closed: {
    rotateY: '0deg',
    opacity: 1,
  },
})

const Bar3 = posed(TogglerBar)({
  open: {
    // x: '3px',
    y: '1px',
    rotate: '-45deg',
    ...barPoseTransition,
  },
  closed: {
    x: 0,
    y: 0,
    rotate: '0deg',
    ...barPoseTransition,
  },
})

const MenuToggler = forwardRef(({ className, ...props }, ref) => (
  <button
    {...props}
    ref={ref}
    className={`menu-toggler block px-2 w-10 h-12 focus:outline-none ${className}`}
  >
    <Bar1 />
    <Bar2 />
    <Bar3 />
  </button>
))

const MenuTogglerPosed = posed(MenuToggler)()

export default MenuTogglerPosed
