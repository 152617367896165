import { graphql } from 'gatsby'
// import React from 'react'
import { createPopup } from './Popup'

const { Popup: NominatePopup, Context } = createPopup()

export default NominatePopup
export { Context as NominatePopupContext }

export const generalSettingsNominateFormFragment = graphql`
  fragment SettingsNominateGoogleFormCodeFragment on Query {
    generalSettings: file(
      relativeDirectory: { eq: "settings" }
      name: { eq: "general" }
    ) {
      yaml: childSettingsYaml {
        googleformNominateCode
      }
    }
  }
`
