// import '@babel/polyfill'
// import 'polyfills'
import React from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from 'components/Header'
import Footer from 'components/Footer'
// import { withStateHandlers, toClass } from 'recompose'
import 'styles/main.css'
import { ParallaxProvider } from 'react-scroll-parallax'

import { IntlProvider, addLocaleData } from 'react-intl'
import { getLocalizedPath } from 'components/L10nLink'

// Locale data
import enData from 'react-intl/locale-data/en'
import bgData from 'react-intl/locale-data/bg'

//l10n
import locales from '../../config/locales'
import en from 'l10n/en.json'
import bg from 'l10n/bg.json'
import NominatePopup from '../components/PopupNominate'
import ApplyPopup from '../components/PopupApply'

const l10n = {
  en,
  bg: {
    ...en,
    ...bg,
  },
}

const {
  Consumer: PageContextConsumer,
  Provider: PageContextProvider,
} = React.createContext({})

export { PageContextProvider, PageContextConsumer }

const {
  Consumer: LayoutContextConsumer,
  Provider: LayoutContextProvider,
} = React.createContext({})

export { LayoutContextConsumer, LayoutContextProvider }

addLocaleData([...enData, ...bgData])

class Layout extends React.Component {
  // goToTopPose = 'hidden'
  state = {
    goToTopPose: `hidden`,

    layoutContext: {
      headerComponent: null,
      headerHeight: 0,
      footerComponent: null,
    },
  }

  updateGoToTopState = () => {
    this.setState({
      goToTopPose: window.scrollY >= window.innerHeight ? 'visible' : 'hidden',
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandlerDebounced)
  }

  render() {
    const { children, pageContext, location } = this.props
    const { lang, originalPath } = pageContext

    return (
          <LayoutContextProvider value={this.state.layoutContext}>
            <PageContextProvider value={pageContext}>
              <ParallaxProvider>
                <StaticQuery
                  query={graphql`
                    query SiteTitleQuery {
                      site {
                        siteMetadata {
                          title
                          siteUrl
                        }
                      }
                    }
                  `}
                  render={data => (
                    <IntlProvider locale={lang} messages={l10n[lang]}>
                      <>
                        <Helmet
                          title={data.site.siteMetadata.title}
                          meta={[]}
                        >
                          <html lang={lang} />
                          {locales.length &&
                            locales.map(({ code, locale }) => {
                              if (code === lang) {
                                return null
                              }

                              const href = `${location.origin ||
                                data.site.siteMetadata.siteUrl}${getLocalizedPath({
                                path: originalPath,
                                code,
                              })}`

                              return (
                                <link
                                  key={`hreflang-${code}`}
                                  rel="alternate"
                                  href={href}
                                  hrefLang={locale}
                                />
                              )
                            })}
                        </Helmet>

                        <Header
                          // ref={this.headerMounted}
                          siteTitle={data.site.siteMetadata.title}
                          location={location}
                        />

                        <div className="flex flex-col">{children}</div>

                        {/* <GoToTop pose={this.state.goToTopPose} /> */}

                        <Footer />
                      </>
                    </IntlProvider>
                  )}
                />
              </ParallaxProvider>
            </PageContextProvider>
          </LayoutContextProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
